
import { Vue, Component } from "vue-property-decorator";

@Component
export default class Clock extends Vue {
    hours?: number = 0;
    minutes?: number = 0;
    seconds?: number = 0;

    mounted() {
        setInterval(this.tick, 1000);
    }

    get getSeconds() {
        return this.seconds?.toString().padStart(2, "0");
    }

    get getMinutes() {
        return this.minutes?.toString().padStart(2, "0");
    }

    get getHours() {
        return this.hours?.toString().padStart(2, "0");
    }

    /**
     * Methods
     */
    tick() {
        this.hours = new Date().getHours();
        this.minutes = new Date().getMinutes();
        this.seconds = new Date().getSeconds();
    }
}
